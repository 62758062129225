<template>
  <div id="sales-reports-view" class="sales-reports-container">
    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'PaymentLedger'">
      <template v-slot:right-end>
        <vs-button @click="viewAllReportData()"
                   class="mt-3 py-4 px-3 ml-4" color="primary">
          {{ viewAll ? $t('ShowLess') : $t('ViewAll') }}
        </vs-button>
      </template>
    </breadcrumb-base>


    <transition name="fade">
      <div class="vx-row">
        <div class="vx-col md:w-full">

          <!--    Error List State-->
          <transition name="fade">
            <div class="h-screen flex w-full bg-img" v-if="errorFetching">
              <div
                class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
                <img src="@/assets/images/pages/svg_error.svg" :alt="$t('NoData')"
                     class="mx-auto mb-4 max-w-full">
                <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('ErrorData') }}</h1>
              </div>
            </div>
          </transition>
          <!--    Empty List State -->
          <transition name="fade">
            <div class="h-screen flex w-full bg-img" v-if="sales.length === 0 && !errorFetching">
              <div
                class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
                <!--                <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
                <!--                     class="mx-auto mb-4 max-w-full">-->
                <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('NoData') }}</h1>
              </div>
            </div>
          </transition>
          <vs-table-modified v-show="sales.length > 0 && !errorFetching" ref="table"
                             :max-items="20" search
                             :data="sales"
          >

            <template slot="thead">

              <vs-th>{{ $t('Date') }}</vs-th>
              <vs-th>{{ $t('TransactionType') }}</vs-th>
              <vs-th>{{ $t('Name') }}</vs-th>
              <vs-th>{{ $t('Quantity') }}</vs-th>
              <vs-th>{{ $t('OldCredit') }}</vs-th>
              <vs-th>{{ $t('NewCredit') }}</vs-th>
              <vs-th>{{ $t('OldBalance') }}</vs-th>
              <vs-th>{{ $t('Value') }}</vs-th>
              <vs-th>{{ $t('NewBalance') }}</vs-th>
              <!--              <vs-th >{{ $t('Actions') }}</vs-th>-->
            </template>
            <template slot-scope="{data}">
              <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                <vs-td>
                  <p class="product-name">{{ tr.created | dateFormat }}</p>
                </vs-td>
                <vs-td>
                  <p class="product-name">{{ getTransactionType(tr) | title }}</p>
                </vs-td>
                <vs-td>
                  <p class="product-name">{{ tr.transaction_product_name }} -
                    {{ tr.transaction_product_value | germanNumberFormat }}</p>
                </vs-td>
                <vs-td>
                  <p class="product-name" v-if="tr.transaction_pins_json_data">
                    {{ tr.transaction_pins_json_data.quantity }}</p>
                </vs-td>
                <vs-td>
                  <p class="product-name" v-if="tr.is_admin != 0">
                    {{ tr.before_credit | germanNumberFormat }}</p>
                </vs-td>
                <vs-td>
                  <p class="product-name" v-if="tr.is_admin != 0">
                    {{ tr.after_credit | germanNumberFormat }}</p>
                </vs-td>
                <vs-td>
                  <p class="product-name" v-if="activeUserInfo.balance_type == 0">
                    {{ tr.before_balance | germanNumberFormat }}</p>
                  <p class="product-name" v-if="activeUserInfo.balance_type == 1">
                    {{ (tr.before_balance - tr.before_credit) | germanNumberFormat }}</p>
                </vs-td>
                <vs-td>
                  <p class="product-name table-value-item">{{ tr.value | germanNumberFormat }}</p>
                </vs-td>
                <vs-td>
                  <p class="product-name" v-if="activeUserInfo.balance_type == 0">
                    {{ tr.after_balance | germanNumberFormat }}</p>
                  <p class="product-name" v-if="activeUserInfo.balance_type == 1">
                    {{ (tr.after_balance - tr.after_credit) | germanNumberFormat }}</p>
                </vs-td>

                <!--                <vs-td>-->
                <!--                  <vs-button @click="printReport(tr)" >Print</vs-button>-->
                <!--                </vs-td>-->

              </vs-tr>
              </tbody>
            </template>
            <template slot="footer">
            </template>

            <template slot="footer-left">
              <div class="flex">
                <vs-new-pagination :total="vTotalPages" v-model="cPage"
                                   v-on:change="listPageChanged">
                </vs-new-pagination>
              </div>
            </template>
          </vs-table-modified>
        </div>
      </div>
    </transition>


    <vx-modal v-if="showPrintModal" component-class="modal-container-fit">
      <template slot="body">
        <div v-if="purchaseData">
          <div style="max-height: 500px; overflow-y: auto;">
            <div class="text-center status-card">
              <div v-if="purchaseData">

                <receipt-print
                  v-on:close="closeModal()"
                  :print-report-type="printReportType"
                  :purchase-data="purchaseData"></receipt-print>
              </div>
            </div>

          </div>

        </div>
      </template>
    </vx-modal>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import moment from 'moment'
import VxModal from '@/layouts/components/custom/VxModal'
import ReceiptPrint from '@/views/components/ReceiptPrint'

export default {
  name: 'PaymentLedger',
  components: {
    ReceiptPrint,
    Datepicker,
    VxModal,
  },
  data() {
    return {
      selectedUser: -1,
      mergeReceipts: false,
      showPrintModal: false,
      purchaseData: null,
      printReportType: null,
      cPage: 1,
      errorFetching: false,
      breadcrumb: [
        {title: 'Home', i18n: 'Home', url: '/'},
        {title: 'Ledger Reports', i18n: 'PaymentLedger', active: true},
      ],
      dynamicType: ['Soft API', 'EPay', 'International Top-Up', 'Direct Recharge', 'Gift card'],
      filterSelectData: {},
      selectedProduct: null,
      selectedCustomer: null,
      selectedProductConfigId: null,
      startDate: null,
      endDate: null,
      customerFilter: [],
      viewAll: false,
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    vPage() {
      return this.$store.state.reports.vPage
    },
    vTotalPages() {
      return this.$store.state.reports.vTotalPages
    },
    sales() {
      return this.$store.state.reports.sales
    },
    productsFilter() {
      return this.$store.state.reports.productsFilter
    },
    salesRaw() {
      return this.$store.state.reports.salesRaw
    },
    salesFilters() {
      return this.$store.state.reports.typeOfFilters || {}
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.sales.length
    },
    users() {
      return this.$store.state.user.users
    },
  },
  methods: {

    printCard() {
      const divContents = document.getElementById('print-cards').innerHTML
      const a = window.open('', '')
      a.document.write('<html>')
      a.document.write('<body >')
      a.document.write(divContents)
      a.document.write('</body></html>')
      a.document.close()
      setTimeout(() => {
        a.print()
      }, 600)
      this.showPrintModal = false
      this.purchaseData = null
    },
    closeModal() {
      this.purchaseData = false
      this.showPrintModal = false
    },
    printReport(data) {
      // Direct Recharge
      if (data.transaction_type === 1 && (data.transaction_dynamic_type == 3 || data.transaction_dynamic_type == 8)) {
        this.printReportType = 3
        this.purchaseData = data.transaction_pins_json_data.transaction_details
      }

      // GiftCards
      if (data.transaction_type === 0 && data.transaction_dynamic_type == 4) {
        data.transaction_pins_json_data.product_name = data.product
        data.transaction_pins_json_data.value = data.value
        this.purchaseData = data.transaction_pins_json_data
        this.printReportType = 4
      } else if (data.transaction_type === 0) {
        this.printReportType = 0
        this.purchaseData = data.transaction_pins_json_data
      }
      // CashCards
      if (data.transaction_type === 1 && (data.transaction_dynamic_type == 0 || data.transaction_dynamic_type == 10) ) {
        this.printReportType = 0
        this.purchaseData = data.transaction_pins_json_data
      }
      // GiftCards
      if (data.transaction_type === 1 && data.transaction_dynamic_type == 4) {
        data.transaction_pins_json_data.product_name = data.product
        data.transaction_pins_json_data.value = data.value
        this.purchaseData = data.transaction_pins_json_data
        this.printReportType = 4
      }
      // International TopUp
      if (data.transaction_type === 1 && data.transaction_dynamic_type == 2) {
        this.printReportType = 2
        this.purchaseData = data.transaction_pins_json_data
      }
      // FlixBus
      if (data.transaction_type === 1 && data.transaction_dynamic_type == 5) {
        this.printReportType = 5
        this.purchaseData = data.transaction_pins_json_data
      }
      this.showPrintModal = true
    },
    getTransactionType(tr) {
      // type_of_filters: {transaction_type: [{id: 0, name: "static"}, {id: 1, name: "dynamic"}],…}
      // transaction_dynamic_type: [{id: 0, name: "Cash card"}, {id: 1, name: "Cash card"}, {id: 2, name: "International Top-Up"},…]
      // transaction_type: [{id: 0, name: "static"}, {id: 1, name: "dynamic"}]
      // wallet_history_type: [{id: 0, name: "credit"}, {id: 1, name: "cash"}]

      if (tr.is_admin == 6) {
        return 'Credit'
      }
      if (tr.is_admin == 1) {
        const w = this.salesRaw.type_of_filters.wallet_history_type
        for (let i = 0; i < w.length; i++) {
          if (w[i].id == tr.wallet_history_type) {
            return tr.wallet_history_mode
          }
        }
      } else {
        const w = this.salesRaw.type_of_filters.transaction_dynamic_type
        for (let i = 0; i < w.length; i++) {
          if (w[i].id == tr.transaction_dynamic_type) {
            return w[i].name
          }
        }
        return 'Cash Card'
      }
    },
    onCustomerFilterChanged() {
      this.filterChanged()
    },
    onCustomerTextChanged(value) {
      const search_text = value.srcElement.value
      const filter = {
        search_text,
      }
      this.$vs.loading()
      return this.$store.dispatch('reports/searchCustomers', {filter})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          if (data.message_type == 1) {
            this.customerFilter = data.customers
          } else {
            this.$vs.notify({
              position: 'top-center',
              title: 'Error',
              text: error.response.data.message || error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
            })
          }
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    onProductFilterChanged() {
      this.filterChanged()
    },

    viewAllReportData() {
      this.viewAll = !this.viewAll;

      this.filterChanged()
    },
    clearFilterData() {
      this.filterSelectData = {}
      this.startDate = null
      this.endDate = null
      this.selectedProduct = null
      this.selectedProductConfigId = null
      this.filterChanged()
    },
    clearFilter(key) {
      this.filterSelectData[key] = null
      this.filterChanged()
    },
    getFilters() {
      const filter = {}
      Object.keys(this.filterSelectData).forEach((key) => {
        if (this.filterSelectData[key] != null) {
          filter[key] = this.filterSelectData[key].replace(key, '')
        }
      })
      if (this.startDate) {
        filter.start_date = moment(this.startDate).format('yyyy-MM-DD')
      }
      if (this.endDate) {
        filter.end_date = moment(this.endDate).format('yyyy-MM-DD')
      }
      if (this.selectedProduct) {
        filter.product_id = this.selectedProduct.replace('product_id', '')
      }
      if (this.selectedProductConfigId) {
        filter.product_configuration_id = this.selectedProductConfigId.replace('product_configuration_id', '')
      }
      if (this.selectedCustomer) {
        filter.organisation_id = this.selectedCustomer.replace('organisation_id', '')
      }
      if (this.selectedUser != -1) {
        filter.user_id = this.selectedUser
      }
      if (this.viewAll) {
        filter['view_all'] = this.viewAll ? 1 : 0;
      }
      return filter
    },
    filterChanged() {
      this.fetchSales('0', this.getFilters())
    },
    listPageChanged(item) {
      this.fetchSales(this.cPage - 1, this.getFilters())
    },
    fetchSales(page = '0', filter = {}, isDownload = false) {
      this.$vs.loading()
      return this.$store.dispatch('reports/fetchPaymentLedger', {page, filter})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    loadUsers(isMore = false) {
      this.$vs.loading()
      this.$store.dispatch('user/fetchSellerUsers', {isMore})
        .then((data) => {
          this.$vs.loading.close()
        })
        .catch((err) => {
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: err.response.data.message || err.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  created() {
    this.$store.commit('reports/RESET')
    // this.fetchSales('0', this.getFilters())
    this.loadUsers()

    if (localStorage.getItem('merge_receipts') == null) {
      localStorage.setItem('merge_receipts', 'false')
    }
    this.mergeReceipts = localStorage.getItem('merge_receipts') == 'true'
  },
}
</script>

<style lang="scss">
.theme-dark {
  #sales-reports-view {
    .td.vs-table--td > span {
      color: white;
    }
  }
}

#sales-reports-view {
  .vs-select--label {
    padding: 0;
  }

  .header-table {
    width: auto;

    th {
      font-weight: normal;
      font-size: 14px;
      min-width: 200px;
    }

    td {
      font-weight: bold;
      font-size: 24px;
      color: rgba(var(--vs-warning), 1);
    }
  }

  .td.vs-table--td > span {
    color: black;
  }


  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .products-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
